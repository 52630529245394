.app {
	position: relative;
	display: block;
	min-height: 100vh;
	color: $color-white;
	&__block {
		padding-top: 88px;

	}
	&__main {
		width: calc(100% - 380px);
		/*
		max-height: calc(100vh - 76px);
		overflow-x: hidden;
		overflow-y: auto;
		*/
		@include media-breakpoint-down(xl) {
			width: calc(100% - 300px);
		}
		@include media-breakpoint-down(lg) {
			width: calc(100% - 100px);
		}
	}
	.m--gradient,
	&.m--gradient {
		background: $background;
	}
	a {
		color: $color-orange;
		text-decoration: none;
		&:hover {
			color: $color-lightblack;
			text-decoration: underline;
		}
	}
}