.auth {
	min-height: 100vh;
	&__left {
		/*background: $color-orange url('./assets/img/bg/cyrcles.svg') 50% 50% no-repeat;*/
		//background: $backgroundOrange;
	}
	&__logo {
		position: relative;
		display: block;
		margin: auto;
		text-align: center;
		@include media-breakpoint-down(md) {
			height: 50px;
		}
		a {
			position: relative;
			display: block;
			max-height: 100%;
		}
		img {
			max-width: 90%;
			max-height: 100%;
			height: auto;
			margin-top: -30px;
			@include media-breakpoint-down(md) {
				height: 100px;
				margin-top: -20px;
			}
		}
	}
	&__right {
		position: relative;
		&-form {
			max-width: 420px;
			width: 100%;
		}
	}
	&__password {
		position: relative;
		&-btn {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 50px;
			background-position: 50% 50%;
			background-size: 30px auto;
			background-repeat: no-repeat;
			background-image: url('./assets/img/icons/eye.svg');
			&.m--show {
				opacity: 0.6;
				filter: grayscale(1);
			}
		}
		input {
			padding-right: 3.5rem;
		}
	}
}