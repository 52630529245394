.campaigns {
    &__main {
    }

    &__title {
    }

    &__content {
    }

    &__list {
    }

    &__item {
        &-info {
            max-width: 700px;
        }

        &-title {
            display: flex;
            gap: 1rem;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.5rem;
            text-transform: uppercase;
            padding-top: 0.5rem;
            margin-bottom: 1rem;

            &-label {
                background: $backgroundOrange;
                color: $color-white;
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5rem;
                padding: 0.25rem 0.75rem;
                margin-top: -0.25rem;
                margin-left: auto;
                margin-bottom: auto;
                border-radius: 0.5rem;
                text-transform: none;
            }

            &-span {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;
                text-transform: none;
                color:$color-grey;
            }

        }

        &-schedule {
            max-width: 800px ;
            width: 100%;
        }



        &-content {
            &:not(:last-child) {
                border-bottom: 1px solid rgba($color-orange, 0.2);
                padding-bottom: 0.75rem;
                margin-bottom: 1rem;
            }

            p {
                margin-bottom: 0.25rem;
                font-weight: 500;

                span {
                    font-weight: 600;
                }
            }

            &-block:not(:last-child) {
                padding-bottom: 0.5rem;
            }
        }

        &-payment {
            display: flex;
            gap: 1.5rem;

            &-sum {
                margin-right: auto;

                span {
                    display: block;
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-bottom: 0.25rem;
                }
            }

            &-promo {
                text-align: right;
                font-size: 0.875rem;
                color: $color-orange;
            }
        }
    }

    &__pagination {
        --bs-pagination-bg: transperent;
        --bs-pagination-border-color: transperent;
        --bs-pagination-active-bg: transperent;
        --bs-pagination-disabled-bg: transperent;
        --bs-pagination-padding-x: 0.8rem;
        --bs-pagination-padding-y: 0.1rem;
        --bs-pagination-active-border-color: #ff7900;
        --bs-pagination-hover-bg: none;
        --bs-pagination-disabled-color: #FFF;
        --bs-pagination-active-color: #ff7900;
        --bs-pagination-color: #FFF;
        --bs-pagination-hover-color: #ff7900;
        --bs-pagination-focus-box-shadow: none;
        --bs-pagination-focus-color: none;
        --bs-pagination-focus-bg: transperent;
        .page-item {
            border: 1px solid transparent;
            &:first-child,
            &:nth-child(2),
            &:nth-last-child(1),
            &:nth-last-child(2){
                span,button{
                    font-size: 24px !important;
                }
            }
        }

        .page-item.active {
            border: 1px solid $color-orange;
        }
    }
}