.topbar {
	width: fit-content;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 1000;
	&__menu {
		position: relative;
		&-item {
			position: relative;
			display: inline-block;
			font-weight: 700;
			font-size: 18px;
			padding: 8px 0 8px 12px;
			vertical-align: middle;
			background: $backgroundOrange;
			border-radius: 4px;
			&:before {
				content: '';
				position: absolute;
				display: block;
				top: 50%;
				margin-top: -15px;
				left: 0;
				width: 28px;
				height: 28px;
				background-position: 0% 50%;
				background-repeat: no-repeat;
				background-size: 24px 24px;
			}
			&.m--icon-person {
				padding: 8px 0 8px 40px;
			}
			&.m--icon-person:before {
				margin-left: 12px;
				background-image: url('./assets/img/icons/person.svg');
			}
			&.m--icon-settings{
				height: 40px;
				width: 40px;
			}
			&.m--icon-settings:before {
				margin: -13px 8px;
				background-image: url('./assets/img/icons/settings.svg');
			}
		}
	}
}