.home {
	min-height: 100vh;
	&__top {
		&-logo {
			width: 342px;
			height: 77px;
			background: url('./assets/img/logo-ldirect.svg') 0 0 no-repeat;
			background-size: 100% auto;
			@include media-breakpoint-down(sm) {
				width: 300px;
				height: 68px;
			}
		}
		&-buttons {}
		&-button {
			width: 315px;
			&.m--gradient {
				position: relative;
				background: linear-gradient(90deg, $color-orange -3.46%, $color-white 104.15%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				color: $color-white;
				mask-image: linear-gradient(90deg, $color-orange -3.46%, $color-white 104.15%);
				-webkit-mask-image: linear-gradient(90deg, $color-orange -3.46%, $color-white 104.15%);
			}
			@include media-breakpoint-down(sm) {
				width: 285px;
			}
		}
		&-banner {
			&-text {
				.text-muted {
					color: $color-gray !important;
				}
			}
			&-icon {
				position: relative;
				display: flex;
				width: 64px;
				height: 64px;
				min-width: 64px;
				min-height: 64px;
				background: $color-orange;
				border-radius: 12px;
				img {
					position: relative;
					display: block;
					width: 32px;
					height: 32px;
					margin: auto;
				}
			}
		}
	}
	&__main {
		background-image: url('./assets/img/bg/map.svg');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: auto 130%;
		@include media-breakpoint-down(lg) {
			background: none;
		}
		&-left {}
		&-right {}
		&-card {
			position: relative;
			background: $color-white-80;
			backdrop-filter: blur(3px);
		}
		&-cities {
			color: $color-white;
			font-weight: bold;
			margin: auto 0;
			text-shadow: 2px 0 0 $color-orange, -2px 0 0 $color-orange, 0 2px 0 $color-orange, 0 -2px 0 $color-orange, 1px 1px $color-orange, -1px -1px 0 $color-orange, 1px -1px 0 $color-orange, -1px 1px 0 $color-orange;
			ul {
				list-style: none;
			}
			li {
				font-size: 20px;
				line-height: 28px;
				transition: all 0.2s ease;
				&:hover {
					font-size: 28px;
				}
			}
		}
	}
	&__info {
		&-logo {
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
	&__promo {
		&-banner {
			position: relative;
			min-height: 300px;
			overflow: hidden;
			.card-body {
				position: relative;
				display: flex;
				flex-direction: column;
			}
			&-background {
				position: absolute;
				display: block;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				img {
					position: relative;
					display: block;
					width: 100%;
					height: 100%;
					max-width: 100%;
					max-height: 100%;
					object-fit: cover;
				}
				&:before {
					content: '';
					position: absolute;
					display: block;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					background: linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%);
					z-index: 1;
				}
			}
			&-video {
				position: relative;
				display: block;
				width: 100px;
				height: 100px;
				background: $color-white-80;
				border-radius: 12px;
				margin: auto;
				z-index: 2;
				transition: opacity 0.15s ease-in-out;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					margin-top: -32px;
					margin-left: -32px;
					width: 64px;
					height: 64px;
					background: $color-orange;
					border-radius: 50%;
				}
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					margin-top: -16px;
					margin-left: -10px;
					width: 0;
					height: 0;
					border-top: 16px solid transparent;
					border-right: 16px solid transparent;
					border-bottom: 16px solid transparent;
					border-left: 24px solid $color-white;
				}
				&:hover {
					opacity: 0.6;
				}
			}
			&-content {
				color: $color-white;
				z-index: 2;
			}
		}
	}
}