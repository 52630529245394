.home-landing {

    min-height: 100vh;
    overflow-x: hidden;

    padding-top: 6rem;

    &__block {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    &__top {
        display: flex;
        gap: 2rem;
        margin-bottom: 8rem;

        @include media-breakpoint-down(xl) {
            margin-bottom: 10rem;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }

        &-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            margin: auto 0;
        }

        &-screen {
            position: relative;
            /*min-height: 740px;*/
            min-height: 710px;
            width: 330px;
            min-width: 330px;
            z-index: 1;

            @include media-breakpoint-down(xl) {
                width: 280px;
                min-width: 280px;
                min-height: 610px;
            }

            @include media-breakpoint-down(lg) {
                width: 224px;
                min-width: 224px;
                min-height: 490px;
                max-height: 490px;
            }

            @include media-breakpoint-down(md) {
                margin: 2rem auto 5rem;
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: 3rem;
                transform: translate(-50%, -50%);
                width: 540px;
                height: 930px;
                background: url('./assets/img/bg/landing-phone.png') 50% 50% no-repeat;
                background-size: contain;
                pointer-events: none;
                z-index: 1;

                @include media-breakpoint-down(xl) {
                    width: 460px;
                    height: 794px;
                }

                @include media-breakpoint-down(lg) {
                    width: 360px;
                    height: 640px;
                }

            }

            &:after {
                content: '';
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 4rem;
                height: 4rem;
                background: url('./assets/img/icons/play.svg') 50% 50% no-repeat;
                background-size: contain;
                pointer-events: none;
                transition: all 0.5s ease;
            }

            &.m--played:after {
                opacity: 0;
                background: url('./assets/img/icons/pause.svg') 50% 50% no-repeat;
            }

            &.m--played:hover:after {
                opacity: 1;
            }

            video {
                position: relative;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
                margin-top: 5px;
                cursor: pointer;
                border-radius: 2rem;

                @include media-breakpoint-down(xl) {
                    margin-top: 10px;
                }

                @include media-breakpoint-down(lg) {
                    margin-top: 20px;
                }
            }
        }

        &-logo {
            position: relative;
            width: 410px;
            height: 84px;
            margin-bottom: 2rem;

            @include media-breakpoint-down(lg) {
                width: 360px;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &.m--mobile {
                display: none;
                margin: 0 auto 0;

                @include media-breakpoint-down(md) {
                    display: block;
                }

                @include media-breakpoint-down(sm) {
                    width: 320px;
                    height: 84px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -2.5rem;
                transform: translate(-50%, -50%);
                width: 490px;
                height: 215px;
                background: url('./assets/img/logo-landing.png') 50% 50% no-repeat;

                @include media-breakpoint-down(lg) {
                    margin-left: -1.25rem;
                }

                @include media-breakpoint-down(sm) {
                    width: 400px;
                    height: 145px;
                    margin-left: -1.75rem;
                    background-size: 100% auto;
                }
            }

            /*
            @include media-breakpoint-down(sm) {
                width: 300px;
                height: 68px;
            }
            */
        }

        &-title {
            font-weight: 700;
            color: $color-orange;
            margin-bottom: 1.25rem;
        }

        &-info {
            font-size: 1.5rem;
            line-height: normal;
            margin-bottom: 2.5rem;

            ul {
                list-style: none;
                padding-left: 0;
                margin-left: 0;

                li {
                    position: relative;
                    padding-left: 1.5rem;

                    &:before {
                        content: '·';
                        position: absolute;
                        display: block;
                        top: 0;
                        left: 0;
                        font-weight: 700;
                    }
                }
            }
        }

        &-buttons {
            display: flex;
            flex-wrap: wrap;
            column-gap: 2.5rem;

            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }
        }

        &-button {
            border-width: 2px;
            min-width: 160px;
            @include media-breakpoint-down(lg) {
                width: 280px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    &__advantages {
        display: flex;
        gap: 2.5rem;
        margin-bottom: 6rem;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }

        &-logo {
            position: relative;
            width: 480px;
            min-width: 480px;
            height: 480px;
            margin: auto 0;

            @include media-breakpoint-down(xxl) {
                width: 380px;
                min-width: 380px;
                height: 380px;
            }

            @include media-breakpoint-down(xl) {
                width: 270px;
                min-width: 270px;
                height: 270px;
            }

            @include media-breakpoint-down(lg) {
                margin: 0 auto;
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 760px;
                height: 760px;
                background: url('./assets/img/bg/landing-logo.png') 50% 50% no-repeat;
                background-size: contain;

                @include media-breakpoint-down(xxl) {
                    width: 600px;
                    height: 600px;
                }

                @include media-breakpoint-down(xl) {
                    width: 430px;
                    height: 430px;
                }
            }
        }

        &-block {
            columns: 2;
            column-gap: 3.5rem;

            @include media-breakpoint-down(md) {
                columns: unset;
            }
        }

        &-item {
            position: relative;
            font-size: 1.125rem;
            line-height: normal;
            page-break-inside: avoid;
            margin-bottom: 2rem;
            min-height: 8rem;
            padding-left: 2rem;
            counter-increment: inst;

            @include media-breakpoint-down(xl) {
                font-size: 1rem;
                min-height: 6rem;
            }

            @include media-breakpoint-down(md) {
                min-height: auto;
            }

            &:before {
                content: counter(inst);
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                font-weight: 700;
            }

            &-title {
                font-weight: 700;
                margin-bottom: 0.25rem;
            }
        }
    }

    &__steps {
        border-color: $color-orange;
        background: rgba($color-white, 0.05);
        min-height: 520px;
        overflow: hidden;
        margin-bottom: 6rem;

        @include media-breakpoint-down(xxl) {
            min-height: 430px;
        }

        @include media-breakpoint-down(xl) {
            min-height: 360px;
        }

        &-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1.5rem 280px 1.5rem 2.5rem;
            background: url('./assets/img/bg/landing-mic.png') 100% 50% no-repeat;

            @include media-breakpoint-down(xxl) {
                padding-right: 200px;
                background-size: auto 460px;
                background-position: 100% 2rem;
            }

            @include media-breakpoint-down(xl) {
                padding-right: 160px;
                background-size: auto 390px;
            }

            @include media-breakpoint-down(lg) {
                padding-top: 2rem;
                padding-bottom: 3rem;
                background-position: 100% 100%;
            }

            @include media-breakpoint-down(lg) {
                padding-right: 2.5rem;
            }
        }

        &-title {
            font-weight: 700;
        }

        &-content {
            font-size: 1.5rem;
            margin-bottom: 2.25rem;
        }

        &-block {
            &-inner {
                position: relative;
                display: flex;
                margin-bottom: 3rem;

                @include media-breakpoint-down(xxl) {
                    margin-bottom: 1.5rem;
                }

                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                }
            }
        }

        &-item {
            position: relative;
            padding: 2.5rem 2.25rem 0 0.25rem;
            border-top: 1px solid $color-orange;

            @include media-breakpoint-down(lg) {
                padding: 0 0 1.5rem 1.25rem;
                border-top: none;
                border-left: 1px solid $color-orange;

                &:last-child {
                    padding-bottom: 0;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: -0.3375rem;
                left: 0.25rem;
                width: 0.675rem;
                height: 0.675rem;
                border-radius: 50%;
                background: $color-orange;

                @include media-breakpoint-down(lg) {
                    top: 0.5rem;
                    left: -0.3375rem;
                }
            }

            &-title {
                font-size: 1.25rem;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 1rem;
                line-height: normal;

                @include media-breakpoint-down(xxl) {
                    font-size: 1.125rem;
                }
            }

            &-content {
                font-size: 1.125rem;

                @include media-breakpoint-down(xxl) {
                    font-size: 1rem;
                }
            }
        }

        &-button {
            min-width: 160px;
        }
    }

    &__about {
        margin-bottom: 6rem;

        &-block {
            line-height: normal;
            margin-bottom: 3rem;
        }

        &-title {
            font-size: 2rem;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 0.75rem;

            @include media-breakpoint-down(xxl) {
                font-size: 1.5rem;
            }

            @include media-breakpoint-down(xl) {
                font-size: 1.25rem;
            }

            @include media-breakpoint-down(lg) {
                font-size: 1.125rem;
            }
        }

        &-subtitle {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 1.25rem;

            @include media-breakpoint-down(xxl) {
                font-size: 1.5rem;
            }

            @include media-breakpoint-down(xl) {
                font-size: 1.25rem;
            }

            @include media-breakpoint-down(lg) {
                font-size: 1rem;
            }
        }

        &-content {
            font-size: 1.5rem;
            max-width: 600px;

            @include media-breakpoint-down(xxl) {
                font-size: 1.25rem;
            }

            @include media-breakpoint-down(xl) {
                font-size: 1.125rem;
            }

            @include media-breakpoint-down(lg) {
                font-size: 1rem;
            }
        }
    }

    &__banner {
        border-color: $color-orange;
        background: rgba($color-white, 0.05);
        min-height: 230px;
        overflow: hidden;
        padding-right: 1rem;
        margin-bottom: 6rem;

        @include media-breakpoint-down(lg) {
            min-height: 170px;
        }

        &.m--bottom {
            padding-right: 0;
        }

        &-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1.5rem 280px 1.5rem 2.5rem;
            background: url('./assets/img/bg/landing-banner.png') 100% 100% no-repeat;

            @include media-breakpoint-down(lg) {
                background-size: auto 180px;
            }

            @include media-breakpoint-down(md) {
                padding: 1.5rem 2.5rem 180px 2.5rem;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }

        &.m--bottom &-inner {
            background: url('./assets/img/bg/landing-phones.png') 100% 100% no-repeat;
            background-size: auto 100%;

            @include media-breakpoint-down(md) {
                background-size: auto 200px;
            }
        }

        &-title {
            font-size: 2rem;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            margin-bottom: 0.75rem;

            @include media-breakpoint-down(lg) {
                font-size: 1.5rem;
            }
        }

        &-subtitle {
            font-size: 2rem;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 1.25rem;

            @include media-breakpoint-down(lg) {
                font-size: 1.5rem;
            }
        }

        &-content {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: normal;

            @include media-breakpoint-down(lg) {
                font-size: 1.25rem;
            }
        }

        &-button {
            min-width: 160px;


            @include media-breakpoint-down(lg) {
                /*width: 280px;*/
            }
        }
    }

    &__categories {
        position: relative;
        margin-bottom: 6rem;
        height: 3rem;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            bottom: 0;
            width: calc(1920px - 16px);
            background: $color-orange-f0;
        }

        &-inner {
            position: absolute;
            display: flex;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            bottom: 0;
            width: calc(1920px - 16px);
            overflow: hidden;
        }

        &-text {
            position: absolute;
            display: flex;
            left: 50%;
            transform: translate(-50%);
            bottom: 0;
            white-space: nowrap;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1.5rem;
            padding: 0.75rem 0.25rem;
            animation: marquee 45s infinite linear;
            /*overflow-x: auto;*/

            &:before {
                content: attr(data-text);
                position: absolute;
                display: flex;
                top: 0;
                right: 100%;
                bottom: 0;
                padding: 0.75rem 0;
                z-index: 1;
            }

            &:after {
                content: attr(data-text);
                position: absolute;
                display: flex;
                top: 0;
                left: 100%;
                bottom: 0;
                padding: 0.75rem 0;
                z-index: 1;
            }
        }
    }

    &__services {
        margin-bottom: 6rem;

        &-title {
            font-weight: 700;
            margin-bottom: 2.5rem;
        }

        &-block {
            display: flex;
            flex-wrap: wrap;
            gap: 2.25rem;
        }

        &-item {
            width: calc(50% - 2.25rem / 2);
            border-color: $color-orange;
            background: rgba($color-white, 0.05);
            padding: 0;

            @include media-breakpoint-down(md) {
            	width: 100%;
            }

            &-inner {
                padding: 1rem 1rem 2rem 1rem;
                display: flex;
                flex-direction: column;
            }
            &-title {
            	font-size: 1.25rem;
            	font-weight: 700;
            	line-height: normal;
            	text-transform: uppercase;
            	margin-bottom: 1rem;

            	@include media-breakpoint-down(xl) {
            		font-size: 1.125rem;
            	}
            }
        }
    }

    &__calc {
        margin-bottom: 6rem;

        &-title {
            font-weight: 700;
        }

        &-block {
            display: flex;
            gap: 4rem;

            @include media-breakpoint-down(lg) {
                flex-direction: column-reverse;
            }

            &-inner {
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                flex-grow: 1;
            }

            &-icon {
                position: relative;
                width: 550px;
                height: 510px;

                @include media-breakpoint-down(xl) {
                    width: 296px;
                    height: 275px;
                }

                @include media-breakpoint-down(lg) {
                    margin: 0 auto;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: 3rem;
                    transform: translate(-50%, -50%);
                    width: 770px;
                    height: 740px;
                    background: url('./assets/img/bg/landing-radio.png') 50% 50% no-repeat;
                    background-size: contain;

                    @include media-breakpoint-down(xl) {
                        width: 490px;
                        height: 470px;
                    }

                    @include media-breakpoint-down(xl) {
                        margin-top: 0;
                        width: 320px;
                        height: 290px;
                    }
                }
            }
        }

        &-regions {
            border-color: $color-orange;
            background: rgba($color-white, 0.05);
            padding: 1rem 1rem 2rem 1rem;
            max-height: 440px;
            height: 100%;


            &-inner {
                padding: 0;
                font-size: 1.125rem;
                overflow: hidden;

                .list-group {
                    margin-top: 0.5rem;
                    max-height: calc(100% - 1.75rem);
                    overflow-y: auto;

                    @include scrollbar(transparent, $color-orange, 2px, 10px);
                }
            }

            &-item {
                background: transparent;
                color: $color-white;
                font-size: 1.125rem;
                padding-left: 0.25rem;
                padding-right: 0.25rem;
                transition: background 0.5s ease;


                &:hover {
                    background: rgba($color-white, 0.05);
                }
            }
        }

        &-count,
        &-count:focus {
            color: $color-white;
            border-color: $color-orange;
            background: rgba($color-white, 0.05);
        }

        &-result {
            font-size: 1.5rem;
            font-weight: 700;
            line-height: normal;
        }

        &-button {
            min-width: 160px;
            margin-right: auto;
            @include media-breakpoint-down(lg) {
                /*width: 280px;*/
            }
        }
    }

    &__reviews {
        margin-bottom: 6rem;

        &-title {
            font-weight: 700;
            margin-bottom: 2.5rem;
        }

        &-block {
            position: relative;
            display: block;
        }

        &-item {
            border-color: $color-orange;
            background: rgba($color-white, 0.05);
            padding: 0;
            height: 100%;

            &-inner {
                padding: 1rem 1rem 2rem 1rem;
            }

            &-name {
                font-size: 1.25rem;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 0.25rem;

                @include media-breakpoint-down(xl) {
                    font-size: 1.125rem;
                }
            }

            &-title {
                font-size: 1.125rem;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0.25rem;

                @include media-breakpoint-down(xl) {
                    font-size: 1rem;
                }
            }

            &-date {
                font-size: 1rem;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 1rem;
            }

            &-content {
                font-size: 1.125rem;
                line-height: normal;

                @include media-breakpoint-down(xl) {
                    font-size: 1rem;
                }
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 2.5rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        padding: 3rem 0;
        margin-bottom: 6rem;

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
        }

        &-copyright {
            flex-grow: 1;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }

        &-menu {
            display: flex;
            flex-direction: column;
            padding-top: 1.5rem;

            &-link#{&}-link {
                margin-bottom: 0.75rem;
                color: $color-white;

                &:hover {
                    color: $color-white;
                    text-decoration: underline;
                }
            }
        }

        &-site {
            @include media-breakpoint-down(lg) {
                flex-grow: 1;
            }

            &-link#{&}-link {
                position: relative;
                display: block;
                white-space: nowrap;
                padding-left: 5.5rem;
                color: $color-white;

                &:hover {
                    color: $color-white;
                    text-decoration: underline;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    margin-left: -84px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 214px;
                    height: 214px;
                    background: url('./assets/img/logo-lradio.png') 50% 50% no-repeat;
                }
            }
        }

        &-developer {
            max-width: 400px;

            &-link#{&}-link {
                position: relative;
                display: block;
                padding-left: 5.5rem;
                color: $color-white;

                &:hover {
                    color: $color-white;
                    text-decoration: underline;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 4rem;
                    height: 4rem;
                    background: url('./assets/img/logo-flex.svg') 50% 50% no-repeat;
                }
            }
        }
    }


    &__main {
        background-image: url('./assets/img/bg/map.svg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: auto 130%;
        @include media-breakpoint-down(lg) {
            background: none;
        }

        &-left {
        }

        &-right {
        }

        &-card {
            position: relative;
            background: $color-white-80;
            backdrop-filter: blur(3px);
        }

        &-cities {
            color: $color-white;
            font-weight: bold;
            margin: auto 0;
            text-shadow: 2px 0 0 $color-orange, -2px 0 0 $color-orange, 0 2px 0 $color-orange, 0 -2px 0 $color-orange, 1px 1px $color-orange, -1px -1px 0 $color-orange, 1px -1px 0 $color-orange, -1px 1px 0 $color-orange;

            ul {
                list-style: none;
            }

            li {
                font-size: 20px;
                line-height: 28px;
                transition: all 0.2s ease;

                &:hover {
                    font-size: 28px;
                }
            }
        }
    }

    &__info {
        &-logo {
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    &__promo {
        &-banner {
            position: relative;
            min-height: 300px;
            overflow: hidden;

            .card-body {
                position: relative;
                display: flex;
                flex-direction: column;
            }

            &-background {
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                img {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                }

                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%);
                    z-index: 1;
                }
            }

            &-video {
                position: relative;
                display: block;
                width: 100px;
                height: 100px;
                background: $color-white-80;
                border-radius: 12px;
                margin: auto;
                z-index: 2;
                transition: opacity 0.15s ease-in-out;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -32px;
                    margin-left: -32px;
                    width: 64px;
                    height: 64px;
                    background: $color-orange;
                    border-radius: 50%;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -16px;
                    margin-left: -10px;
                    width: 0;
                    height: 0;
                    border-top: 16px solid transparent;
                    border-right: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    border-left: 24px solid $color-white;
                }

                &:hover {
                    opacity: 0.6;
                }
            }

            &-content {
                color: $color-white;
                z-index: 2;
            }
        }
    }

    .card-body {
        color: $color-white;
    }

}

@keyframes marquee {
    0% {
        transform: translateX(0)
    }
    100% {
        transform: translateX(-99.9%)
    }
}