.campaign {
    &__main {
    }

    &__title {
    }

    &__content {
    }

    &__steps {
        max-width: 700px;
        width: 100%;
        &-item {
            position: relative;
            display: block;
            padding-left: 20px;
            padding-right: 4px;
            color: $color-white;
            pointer-events: none;
            font-weight: 600;

            span:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: 0;
                width: 16px;
                height: 16px;
                border: 1px solid $color-gray;
                border-radius: 50%;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 4px;
                height: 4px;
                background: $color-white;
                border-radius: 2px;
            }

            &.is-active {
                color: $color-middleblack;
                background-image: $backgroundOrange;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &:before {
                    background: $backgroundOrange;
                }

                span {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: 0;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: $backgroundOrange;
                        border: none;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        top: 60%;
                        left: 4px;
                        margin-top: 0;
                        padding: 4px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: black;
                    }
                }
            }

            &.is-completed {
                color: $color-darkgray;
                pointer-events: all;
                cursor: pointer;

                &:before {
                    background: $color-darkgray;
                }

                &:after {
                    border: none;
                    background: url('./assets/img/icons/completed.svg') 50% 50% no-repeat;
                    background-size: 16px 16px;
                }
            }
        }
    }

    &__form {
        width: 100%;
        max-width: 800px;

        &-region {
            cursor: pointer;
            border: 1px solid $color-orange;
        }
    }

    &__ads,
    &__pay {
        width: 100%;
        max-width: 920px;
    }

    &__times {
        width: 100%;
        display: block;
        &-wrapper{
            position: relative;
        }
        &-row {
            text-align: center;
            line-height: 1.5;
            min-width: 660px;

            .m--col {
                width: 3.8%;
                min-width: 25px;
                font-size: 14px;

                .form-check {
                    padding-left: 1.6em;
                }
            }

            .m--col {
                .btn {
                    padding: 0;
                    margin: 0;
                    width: 1em;
                    height: 1em;
                    border-radius: 0.25em;
                    margin-top: 0.125rem;

                    &.btn-outline-warning:not(.active) {
                        border-color: $color-lightgray;
                    }

                    &:hover {
                        background-color: transparent;
                    }

                    &.active:hover {
                        background-color: $color-orange;
                    }
                }
            }

            .m--col.active {
                .btn-check[disabled] + .btn-outline-warning {
                    background: $backgroundOrange;
                    opacity: 1;
                }

                .btn-check[disabled] + .btn-outline-warning:not(.active) {
                    background: transparent;
                }
            }

            .btn-check[disabled] + .btn-outline-warning {
                background: $color-lightgray;
            }

            .form-check-input {
                margin-left: -1em;
            }
        }
    }

    &__ads {
        &-item {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            border: 2px solid $color-orange-40;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:hover,
            &.is-active {
                border: 2px solid $color-orange;
                filter: drop-shadow(0px 4px 10px rgba(255, 121, 0, 0.4));
            }
        }
        &-description{
            background: $backgroundOrange;
            padding: 1.3rem;
            margin-bottom: 1.5rem;
            gap: 5px;
        }
    }

    &__price {
        color: $color-darkgray;
    }
}
