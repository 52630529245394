.sidebar {
	position: relative;
	height: 100%;
	width: 280px;
	@include media-breakpoint-down(xl) {
		width: 260px;
		position: sticky;
		top: 0;
	}
	@include media-breakpoint-down(lg) {
		width: 58px;
	}
	&__logo {
		position: relative;
		margin-top: -4.8em;
		z-index: 1030;
		img {}
	}
	&__menu {
		position: relative;
		&-title {
			font-size: 18px;
			font-weight: 700;
		}
		&-item {
			position: relative;
			display: block;
			font-weight: 600;
			color: $color-white !important;
			padding: 0 0 0 42px;
			margin: 12px 0;
			&:before {
				content: '';
				position: absolute;
				display: block;
				top: 50%;
				margin-top: -15px;
				left: 0;
				width: 30px;
				height: 30px;
				border-radius: 12px;
				background: $backgroundOrange;
				box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
			}
			&:after {
				content: '';
				position: absolute;
				display: block;
				top: 50%;
				margin-top: -15px;
				left: 0;
				width: 30px;
				height: 30px;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: 15px 15px;
			}
			&.is-active,
			&.is-subactive {
				border-radius: 15px;
				background: $backgroundOrange;
				padding: 12px 12px 12px 58px;
				font-size: 18px;
				line-height: 30px;
				font-weight: 700;
				color: $color-white !important;
				box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
				&:before {
					left: 16px;
					box-shadow: none;
					background: none;
				}
				&:after {
					left: 16px;
				}
			}
			&.m--icon-home:after {
				background-image: url('./assets/img/icons/home-white.svg');
			}

			&.m--icon-campaign:after {
				background-image: url('./assets/img/icons/campaign-white.svg');
			}

			&.m--icon-campaigns:after {
				background-image: url('./assets/img/icons/campaigns-white.svg');
			}

			&.m--icon-reports:after {
				background-image: url('./assets/img/icons/reports-white.svg');
			}

			&.m--icon-pay:after {
				background-image: url('./assets/img/icons/pay-white.svg');
			}

			&.m--icon-profile:after {
				background-image: url('./assets/img/icons/profile-white.svg');
			}

			&.m--icon-login:after {
				background-image: url('./assets/img/icons/login-white.svg');
			}

			&.m--icon-logout:after {
				background-image: url('./assets/img/icons/logout-white.svg');
			}

			&:hover {
				text-decoration: none !important;
				color: $color-gray !important;
			}
		}
		@include media-breakpoint-down(lg) {
			&-title {
				display: none;
			}
			&-item {
				font-size: 0;
				height: 42px;
				width: 42px;
				margin: 6px 0;
				&:before {
					border: 1px solid transparent;
				}
				&:before,
				&:after {
					width: 42px;
					height: 42px;
					margin-top: -18px;
				}
				&:after{
					background-size: 22px 22px;
				}
				&:hover:not(.is-subactive) {
					&:before {
						border: 1px solid $color-orange;
					}
				}
				&.is-active,
				&.is-subactive {
					font-size: 0;
					padding: 0 0 0 42px;
					background-color: transparent;
					box-shadow: none;
					&:before,
					&:after {
						left: 0;
						width: 42px;
						height: 42px;
						margin-top: -18px;
						background-size: 22px 22px;
					}
				}
			}
		}
	}
	&__helper {
		border-radius: 15px;
		background: $backgroundOrange;
		@include media-breakpoint-down(lg) {
			display: none;
		}
		&-icon {
			position: relative;
			display: block;
			width: 36px;
			height: 36px;
			background-color: $color-white;
			border-radius: 12px;
			&:before {
				content: '';
				position: absolute;
				display: block;
				top: 50%;
				left: 50%;
				margin-top: -12px;
				margin-left: -12px;
				width: 24px;
				height: 24px;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: 24px 24px;
			}
			&.m--icon-question:before {
				background-image: url('./assets/img/icons/question.svg');
			}
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			color: $color-white;
		}
		&-info {
			color: $color-white;
		}
	}
}