/* Стандартные цвета */
$color-black: #000000;
$color-middleblack: #2d3748;
$color-lightblack: #525252;

$color-darkgray: #828282;
$color-gray: #a0aec0;
$color-lightgray: #e4dbd2;
$color-blue: #6b11fe;

$color-white: #ffffff;
$color-white-10: rgba(255, 255, 255, 0.1);
$color-white-70: rgba(255, 255, 255, 0.7);
$color-white-80: rgba(255, 255, 255, 0.8);

$color-grey: #d9d9d9;
$color-orange: #ff7900;
$color-orange-f0: #f05a40;
$color-orange-40: rgba(255, 121, 0, 0.4);
$color-orange-80: rgba(255, 121, 0, 0.8);



/* Стили блоков */

$backgroundOrange: linear-gradient(272deg, #FF7900 0%, #F05A40 43.97%);

$background: linear-gradient(106.62deg, $color-black 33.59%, $color-blue 78.05%);


/* Шрифты */
$font-family-nunito: 'Nunito', sans-serif;

/* Базовый шрифт */
$font-family-base: $font-family-nunito;
$font-size-base: 16px;
$line-height-base: 24px;
$color-base: $color-lightblack;

/* Текстовый шрифт */
$font-family-text: $font-family-nunito;
$font-size-text: 16px;
$line-height-text: 24px;
$color-text: $color-lightblack;


$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
) !default;

/* Сетка для рабочего поля */

/* Сетка для модального окна */
$modal-width: 635px;
$modal-col-count: 8;
$modal-col-width: 68px;
$modal-col-gap: 0px;
/* $modal-margin: calculate(($modal-width - $modal-col-width * $modal-col-count - $modal-col-gap * ($modal-col-count - 1)) / 2); */

/* Адаптив */