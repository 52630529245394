/* nunito-regular - latin_cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/nunito-v20-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/nunito-v20-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/nunito-v20-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/nunito-v20-latin_cyrillic-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-700 - latin_cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/nunito-v20-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/nunito-v20-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-italic - latin_cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/nunito-v20-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/nunito-v20-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/nunito-v20-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/nunito-v20-latin_cyrillic-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-700italic - latin_cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/nunito-v20-latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/nunito-v20-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/nunito-v20-latin_cyrillic-700italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
